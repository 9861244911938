const getDateValue = date => {
  const time = new Date(date);

  return time.getTime();
};

const useFilters = (data, filters) => {
  const filterKeys = Object.keys(filters);

  const isFiltered =
    filterKeys.some(filterKey => filters[filterKey].length !== 0) ?? true;

  const filteredData = data
    .filter(elem =>
      filterKeys.every(filterKey => {
        return filters[filterKey].length > 0
          ? filters[filterKey].includes(elem[filterKey] || 'na')
          : true;
      })
    )
    .sort((a, b) => getDateValue(b.date) - getDateValue(a.date));

  const count = filterKeys.map(filter => {
    const reducer = filteredData.reduce((result, item) => {
      result[item[filter]] = (result[item[filter]] || 0) + 1;
      return result;
    }, {});

    return { type: filter, options: reducer };
  });

  return { count, filteredData, isFiltered };
};

export default useFilters;
